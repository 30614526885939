.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.order-goods {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}
.order-goods .goods-img {
  width: 2.2rem;
  height: 2.2rem;
  position: relative;
}
.order-goods .goods-img img {
  width: 2.2rem;
  height: 2.2rem;
}
.order-goods .goods-img .group-tip {
  padding: 3px 6px;
  border-radius: 1000px;
  line-height: 1;
  font-size: 12px;
  color: #fff;
  position: absolute;
  left: 1px;
  top: 0.1rem;
  box-sizing: border-box;
  white-space: nowrap;
  background-color: #fe552e;
  transform: scale(1);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.order-goods .goods-img .group-tip span {
  font-size: 0.22rem;
  color: #fff;
  line-height: 0.32rem;
  white-space: nowrap;
}
.order-goods .goods-info {
  height: 2.2rem;
  flex: 1;
  padding: 0.24rem 0.1rem 0.2rem 0.2rem;
  box-sizing: border-box;
  background: #fff;
}
.order-goods .goods-info .goods-name {
  font-size: 0.28rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.4rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
.order-goods .goods-info .goods-intro {
  font-size: 0.24rem;
  color: #999999;
  line-height: 0.34rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  margin-bottom: 0.12rem;
}
.order-goods .goods-info .sku-box {
  display: flex;
  margin-bottom: 0.16rem;
}
.order-goods .goods-info .sku-box .goods-sku {
  height: 0.36rem;
  line-height: 0.36rem;
  padding: 0 0.12rem;
  box-sizing: border-box;
  border-radius: 0.36rem;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  font-size: 0.22rem;
}
.order-goods .goods-info .goods-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.order-goods .goods-info .goods-price .price .real-price {
  color: #333;
  margin-right: 6px;
}
.order-goods .goods-info .goods-price .price s {
  color: rgba(0, 0, 0, 0.38);
}
.order-goods .goods-info .goods-price .num {
  color: #333;
}
.order-goods .fs-weight {
  font-weight: bold;
}
.order-goods .fs-12 {
  font-size: 0.24rem;
}
.order-goods .fs-16 {
  font-size: 0.32rem;
}
