.border-none[data-v-bc1e8ca0]:before {
  display: none;
}
.border-none[data-v-bc1e8ca0]:after {
  display: none;
}
.flex-fix[data-v-bc1e8ca0] {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: 0px;
      flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar[data-v-bc1e8ca0]::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover[data-v-bc1e8ca0] {
  position: absolute;
  top: 0;
  right: 0;
}
.center[data-v-bc1e8ca0] {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ct[data-v-bc1e8ca0] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.cl[data-v-bc1e8ca0] {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.clear-fix[data-v-bc1e8ca0] {
  *zoom: 1;
}
.clear-fix[data-v-bc1e8ca0]:before,
.clear-fix[data-v-bc1e8ca0]:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.order-goods[data-v-bc1e8ca0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.2rem;
}
.order-goods .goods-img[data-v-bc1e8ca0] {
  width: 2.2rem;
  height: 2.2rem;
  position: relative;
}
.order-goods .goods-img img[data-v-bc1e8ca0] {
  width: 2.2rem;
  height: 2.2rem;
}
.order-goods .goods-img .group-tip[data-v-bc1e8ca0] {
  padding: 3px 6px;
  border-radius: 1000px;
  line-height: 1;
  font-size: 12px;
  color: #fff;
  position: absolute;
  left: 1px;
  top: 0.1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  white-space: nowrap;
  background-color: #fe552e;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}
.order-goods .goods-img .group-tip span[data-v-bc1e8ca0] {
  font-size: 0.22rem;
  color: #fff;
  line-height: 0.32rem;
  white-space: nowrap;
}
.order-goods .goods-info[data-v-bc1e8ca0] {
  height: 2.2rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 0.24rem 0.1rem 0.2rem 0.2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: #fff;
}
.order-goods .goods-info .goods-name[data-v-bc1e8ca0] {
  font-size: 0.28rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 0.4rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
}
.order-goods .goods-info .goods-intro[data-v-bc1e8ca0] {
  font-size: 0.24rem;
  color: #999999;
  line-height: 0.34rem;
  display: -webkit-box;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  word-wrap: break-word;
  margin-bottom: 0.12rem;
}
.order-goods .goods-info .sku-box[data-v-bc1e8ca0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0.16rem;
}
.order-goods .goods-info .sku-box .goods-sku[data-v-bc1e8ca0] {
  height: 0.36rem;
  line-height: 0.36rem;
  padding: 0 0.12rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 0.36rem;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  font-size: 0.22rem;
}
.order-goods .goods-info .goods-price[data-v-bc1e8ca0] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.order-goods .goods-info .goods-price .price .real-price[data-v-bc1e8ca0] {
  color: #333;
  margin-right: 6px;
}
.order-goods .goods-info .goods-price .price s[data-v-bc1e8ca0] {
  color: rgba(0, 0, 0, 0.38);
}
.order-goods .goods-info .goods-price .num[data-v-bc1e8ca0] {
  color: #333;
}
.order-goods .fs-weight[data-v-bc1e8ca0] {
  font-weight: bold;
}
.order-goods .fs-12[data-v-bc1e8ca0] {
  font-size: 0.24rem;
}
.order-goods .fs-16[data-v-bc1e8ca0] {
  font-size: 0.32rem;
}
